@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(open-sans-v40-latin-regular.woff2) format('woff2'),
  url(open-sans-v40-latin-regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url(open-sans-v40-latin-500.woff2) format('woff2'),
  url(open-sans-v40-latin-500.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url(open-sans-v40-latin-700.woff2) format('woff2'),
  url(open-sans-v40-latin-700.ttf) format('truetype');
}
