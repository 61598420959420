@use '@angular/material' as mat;
@use '../../../app/modules/history/components/history-entry.component' as timeline-component;

@import "./fonts/material-icons/material-icons.scss";
@import "./fonts/opensans/opensans.scss";
@import "@angular/material/theming";

/* Primary color palette */
$primary-palette: (
  50 : #fef4e5,
  100 : #fee4bf,
  200 : #fdd294,
  300 : #fcc069,
  400 : #fbb348,
  500 : #faa528,
  600 : #f99d24,
  700 : #f9931e,
  800 : #f88a18,
  900 : #f6790f,
  A100 : #ffffff,
  A200 : #fff6ef,
  A400 : #ffd8bc,
  A700 : #ffc9a2,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

/* Accent color palette */
$accent-palette: (
  50 : #e5f7fd,
  100 : #bdebfa,
  200 : #92def6,
  300 : #66d0f2,
  400 : #45c6f0,
  500 : #24bced,
  600 : #20b6eb,
  700 : #1bade8,
  800 : #16a5e5,
  900 : #0d97e0,
  A100 : #ffffff,
  A200 : #d9f0ff,
  A400 : #a6ddff,
  A700 : #8cd3ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

/* Theming */
$fx-primary: mat.define-palette($primary-palette, 500);
$fx-accent: mat.define-palette($accent-palette, A200, A100, A400);

/* Color */
$primary-color: mat.get-color-from-palette($primary-palette, 500);
$primary-color-faded: mat.get-color-from-palette($primary-palette, 50);
$primary-color-category: mat.get-color-from-palette($primary-palette, 100);
$secondary-color: mat.get-color-from-palette($accent-palette, 500);
$secondary-color-faded: mat.get-color-from-palette($accent-palette, 50);
$hover-color: mat.get-color-from-palette($primary-palette, 50);
$lighten-primary-color: mat.get-color-from-palette($primary-palette, 200);
$gradient-light: mat.get-color-from-palette($primary-palette, 300);
$gradient-dark: mat.get-color-from-palette($primary-palette, 800);

/* Fonts */
$fx-typo: mat.define-typography-config(
  $font-family: Open Sans,
  $headline: mat.define-typography-level(25px, 27px, 700),        /* H1 */
  $title: mat.define-typography-level(18px, 25px, 500),           /* H2 */
  $subheading-2: mat.define-typography-level(18px, 25px, 500),    /* H3 */
  $subheading-1: mat.define-typography-level(18px, 25px, 700),    /* H4 */
  $body-1: mat.define-typography-level(16px, 22px, 400),          /* Base body text */
  $body-2: mat.define-typography-level(16px, 22px, 500),          /* Bold body text */
  $button: mat.define-typography-level(16px, 22px, 700)          /* Buttons and anchors */
);

$fx-theme: mat.define-light-theme((
  color: (
    primary: $fx-primary,
    accent: $fx-accent
  ),
  typography: $fx-typo,
));

@include mat.core($fx-typo);
@include mat.all-component-themes($fx-theme);

@include timeline-component.theme($fx-theme);

@media (max-width : 600px) {
  $fx-typo: mat.define-typography-config(
    $font-family: Open Sans,
    $headline: mat.define-typography-level(22px, 27px, 700),        /* H1 */
    $title: mat.define-typography-level(16px, 25px, 500),           /* H2 */
    $subheading-2: mat.define-typography-level(16px, 25px, 500),    /* H3 */
    $subheading-1: mat.define-typography-level(16px, 25px, 700),    /* H4 */
    $body-1: mat.define-typography-level(14px, 22px, 400),          /* Base body text */
    $body-2: mat.define-typography-level(14px, 22px, 500),          /* Bold body text */
    $button: mat.define-typography-level(14px, 22px, 700)          /* Buttons and anchors */
  );
  @include mat.core($fx-typo);
}

/* Basic Typo Color */
$font: #4a4a4a;
$error-color: red;

/* Material card styling */
.mat-card {
  background: white;
}

.mat-card,
.mat-card-title,
.mat-card-subtitle,
body {
  color: $font;
}

/* Button */
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}

/* Primary container styling */
.primary-container {
  background-color: white;
  color: $font;
}

/* Button on primary color */
.primary-contrast-button {
  color: #707070;
  .hover-border {
    background-color: $primary-color;
  }
}

.mat-subheading-1 {
  color: #707070;
}

.font-weight-bold {
  font-weight: bold !important;
}

.color-thema {
  color: white;
}

.primary-background {
  background-color: $primary-color;
}

.mat-icon.mat-primary {
  color: $primary-color;
}

/* Override link styling */
a {
  color: $primary-color;
  .hover-border {
    background-color: $primary-color;
  }
  &:hover {
    color: $primary-color;
  }
}

/* Styling for secondary contrast color */
.mat-button.mat-secondary-contrast,
.mat-stroked-button.mat-secondary {
  color: $font;
}

.mat-icon-button.mat-secondary-contrast {
  color: $font;
}

.mat-icon-button:hover {
  color: $primary-color;
}
/* Header */
.energyType {
  background: transparent linear-gradient(180deg, $gradient-light 0%, $gradient-dark 100%) 0% 0% no-repeat padding-box;
}

.cdk-overlay-connected-position-bounding-box {
  hr {
    border: 1px solid $font;
  }
}

/* Footer */
.linear-gradient {
  background: transparent linear-gradient(270deg, $gradient-light 0%, $gradient-dark 100%) 0% 0% no-repeat;
}

/********************
    Formular
********************/

.mat-select-value,
.mat-input-element,
.mat-form-field-label {
  color: $font !important;
}

// Error
.mat-form-field.mat-form-field-invalid .mat-form-field-label,
.mat-error,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker,
.mat-form-field-invalid .mat-input-element,
span.error {
  color: $error-color !important;
  caret-color: $error-color !important;
}

.mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
  padding: 0 !important;
}

.mat-error,
span.error {
  .mat-icon {
    color: $error-color;
  }
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: $error-color;
}

// Indikation
.hints {
  .mat-card-title {
    color: $primary-color;
  }
}

ul {
  li {
    span {
      border: 2px solid $primary-color;
      color: $primary-color;
    }
  }
}

.mat-icon.icon-post {
  color: $primary-color;
}

.amend {
  color: $lighten-primary-color;
  .mat-icon {
    color: $lighten-primary-color;
  }
  &:hover {
    background: $hover-color;
    .mat-icon {
      color: $primary-color;
    }
  }
}

// HomeLink
.homeLink {
  height: 60px;
  background: no-repeat url(./logos/logo.svg) center center;
  background-position-x: left;
  background-size: contain;
  margin-left: 1rem;
  @media all and (min-width: 640px) {
    height: 72px;
    background-position-x: center;
    margin-left: 0;
  }
}

// List Numbers
ul li span {
  padding: 1px 8px;
}

//ContractHeader

.contractHeader {
  background: linear-gradient(0.25turn, #fcc069, #f6790f);
  position: relative;
  .energySymbol {
    position: absolute;
    right: 10%;
    width: 75px;
    height: 85px;
    top: 0;
    &.electricity {
      background: no-repeat url(./logos/symbolElectricity.svg) top center;
    }
    &.gas {
      background: no-repeat url(./logos/symbolGas.svg) top center;
    }
  }
}

// Faded BG
.fadedBG {
  background: linear-gradient(0.25turn, $primary-color-faded, $secondary-color-faded);
}

// Color
.primaryColor {
  color: $primary-color;
}
.primaryColorFaded {
  background-color: $primary-color-faded;
}

//CUSTOM CSS ORDER STEPPER
.orderStepper {
  .mat-step-icon-selected {
    background-color: $primary-color !important;
  }
  .mat-step-label {
    opacity: .5;
  }
  .mat-step-label-selected {
    opacity: 1;
    .mat-subheading-1 {
      color: $primary-color;
    }
  }
  .mat-vertical-content {
    padding-bottom: 0;
  }
}

.primaryCategoryBackgroundColor {
  background-color: $primary-color-category;
}

.category-favourite {
  background-color: $primary-color;
  color: white;
  mat-icon{
    color: white !important;
  }

  mat-card-actions span {
    color: white;
  }
}

.border-primary-color {
  border-color: $primary-color
}

.tooltip-primary {
  background: $primary-color;
  font-size: 12px;
  line-height: 1.35;
}
